<template>
  <el-dialog :visible.sync="isShow" :destroy-on-close="true" custom-class="custom-dialog el-dialog-center2"
    :modal-append-to-body="false">
    <div class="dio-title">{{ data.title }}</div>
    <div v-if="turntableShow" class="ed-box">
      <div class="notify-wrap">
        <img src="@/assets/images/pdd-logo.png" alt="">
        {{ $t("快速取出") }}
      </div>
      <turntable :borderShow="false" class="turntable" :data="data" @tabBtn="turntable" ref="turntable"></turntable>
      <div class="bottom-desc">{{ $t("免费旋转并赢得真钱") }}</div>
    </div>

    <div v-else class="unveil-page">
      <div class="cashText">{{ $t("恭喜你赢得") }}:</div>
      <div class="noWithdraw">
        <span>{{ data.current_symbol }}</span>
        <animate-number :formatter="formatNumber" from="0" :to="data.awarded_amount" :key="data.awarded_amount"
          duration="1500" easing="easeOutQuad" from-color="#fff500" to-color="#fff500"
          style="font-size: 40px;margin-left: 6px;"></animate-number>
      </div>

      <div class="jdt-wapper">
        <div class="jdt-txt">
          <span>{{ formatNumber(numFormat(data.awarded_amount / data.amount * 100 || 0)) }}%</span>
        </div>
        <div class="progress">
          <div class="progress-content" :style="`width: ${(data.awarded_amount / data.amount * 100 || 0)}%;`">
          </div>
        </div>
        <div class="needCash">{{ $t("您还需要") }}

          <span class="num">{{ data.current_symbol }}
            <animate-number :formatter="formatNumber" from="0" :to="numFormat((data.amount - data.awarded_amount) || 0)"
              duration="1500" :key="data.amount - data.awarded_amount" easing="easeOutQuad" from-color="#fff500"
              to-color="#fff500"></animate-number>
          </span>
          {{ $t("才能提款") }}
        </div>
        <button type="button" @click="goPddPage" class="cashButton ">{{ $t("要求取出更多") }}</button>
      </div>

      <div class="line"></div>
      <div class="withdrawTips">
        <img src="@/assets/images/pdd-logo.png" alt="">
        <span>{{ $t("成功取出") }}</span>
      </div>
      <div class="listContent">
        <div class="cashList" :style="{ 'animation-duration': `${data.withdrawal_user_list.length * 0.5}s` }">
          <div class="list-item" v-for="item, index in data.withdrawal_user_list" :key="index">
            <span>{{ item.username }}</span>
            <span>{{ $t('刚刚提现') }}</span>
            <span>{{ data.current_symbol }} {{ item.amount }}</span>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>
    
<script>
import turntable from "./turntable.vue"
import { pdd_getData_api, pdd_turntable_api } from '@/api/pdd.js'
export default {
  components: { turntable },
  data() {
    return {
      isShow: false,
      turntableShow: true,
      data: {},
      fristState: true
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.query.pdd == "show") {
          this.show()
        } else {
          if (this.fristState) {
            this.fristState = false
          } else {
            this.hide(true)
          }
        }
      },
      immediate: true
    },
    isShow(v) {
      if (!v) {
        this.$router.push({ query: {} });
      }
    }
  },
  mounted() {
    // if (!this.verifyForThefirst()) {
    //   this.goUrl('/?pdd=show')
    // }
    if(!this.$store.state.pdd.pddData) this.getData()
  },
  methods: {
    verifyForThefirst() {
      const key = "pddPopHideTime";
      const date = new Date();
      const time = `${date.getFullYear()}/${date.getMonth() +
        1}/${date.getDate()}`;
      // 判断时间相等不显示，不相等进行显示
      return time == localStorage.getItem(key);
    },
    async show() {
      await this.getData()
      if (this.data.awarded_amount > 0) {
        this.hide(true)
        if (this.$route.path == "/") {
          this.$router.replace("/")
        }
        this.$router.push("/pdd")

      } else {
        this.isShow = true
      }
    },
    hide(isDay = false) {
      const key = "pddPopHideTime";
      if (isDay) {
        const date = new Date();
        const time = `${date.getFullYear()}/${date.getMonth() +
          1}/${date.getDate()}`;
        localStorage.setItem(key, time);
      } else {
        localStorage.setItem(key, null);
      }
      this.isShow = false;
      this.turntableShow = true
    },
    goPddPage() {
      this.turntableShow = true
      this.$router.push("/pdd")
    },
    // 抽奖
    async turntable() {
      if (!this.$store.state.isLoginStatu) {
        this.$router.push('/?modal=LoginRegistration&data=tab-0')
        return
      }
      if (this.tLoading) return
      this.tLoading = true
      const { data } = await pdd_turntable_api({ id: this.data.id })
      if (data.code == 1) {
        this.getData()
        this.$refs.turntable.run(async () => {
          this.tLoading = false
          if (data.data.amount > 0) {
            this.data.awarded_amount = data.data.amount
            setTimeout(() => {
              this.turntableShow = false
            }, 2000)

          } else {
            this.errorTips("很遗憾，您未中奖");
          }
        }, data.data.amount)
      } else {
        this.tLoading = false
        this.errorTips(data.msg);
      }
    },
    async getData() {
      const { data } = await pdd_getData_api()
      if (data.code == 1) {
        this.$store.commit("pdd/changeData",data.data)
        this.data = data.data
      } else {
        this.errorTips(data.msg)
      }
    }
  }
}
</script>
    
<style scoped lang="less">
/deep/.el-dialog__header {
  padding: 11px 20px 4px;
}

/deep/.el-dialog__headerbtn {
  position: absolute;
  top: 8px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

/deep/ .custom-dialog .el-dialog__header::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
  background: #297343;
  transition: bottom .3s;
  border: none;
  margin: 0;
}

/deep/.el-dialog__body {
  border: none;
  margin: 0;
  padding: 0;
  // padding-bottom: 30px;
  background-color: linear-gradient(0deg, #0b4721, #11652f 0);
  ;
}

.ed-box {
  width: 100%;
  // height: 80vh;
  padding-bottom: 30px;
  background: url(../../assets/images/pdd-bg.jpg) no-repeat;
  background-size: cover;

  img {
    width: auto;
    height: 20px;
    object-fit: fill;
  }
}

.custom-dialog {
  padding: 0 !important;
}

.notify-wrap {
  width: 100%;
  height: 42px;
  color: #c6c6c6;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 8px;
  }
}

.withdrawLogs {
  font-size: 13px;
  line-height: 13px;
  height: 20px;
  width: 100%;
  margin-left: 2px;
}

.van-notice-bar__wrap {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  align-items: center;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.haveCash {
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

.dio-title {
  padding-left: 30px;
  padding-bottom: 8px;
  font-size: 18px;
  color: #fff600;
  background: #297343;
}

.title-icon {
  width: 1.8em;
  margin-right: 9px;
}

.top-desc {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  margin-bottom: -30px;
  margin-top: 30px;

  @media (max-width:768px) {
    font-size: 14px;
    margin: 0;
    margin-top: 20px;
  }
}

.bottom-desc {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: -30px;

  @media (max-width:768px) {
    font-size: 14px;
    margin: 0;
  }
}

.turntable {
  @media (max-width:768px) {
    transform: scale(1.1);
  }
}

.unveil-page {
  width: 100%;
  padding: 0 15px 30px;
  background-color: #105f2c;

  img {
    width: auto;
    height: 20px;
    object-fit: fill;
  }
}

.cashText {
  font-size: 13px;
  color: #e6e6e6;
  padding-top: 20px;
  text-align: center;
}

.noWithdraw {
  font-size: 30px;
  color: #fff500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.jdt-wapper {
  // margin-top: 8px;
  color: #fff;
  text-align: center;
}

.jdt-txt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
}

.progress {
  height: 23px;
  border-radius: 100vh;
  background-color: #00251D;
  // margin-top: 15px;

  @media (max-width:768px) {
    height: 16px;
  }
}

.progress-content {
  max-width: 100%;
  width: 0%;
  height: 100%;
  background: #f5a420;
  border-radius: 100vh;
  transition: all .3s;
}

.num {
  color: #fff500;
  font-size: 1.3em;
}

.needCash {
  text-align: center;
  padding: 8px 0 16px;
}

.cashButton {
  width: 90%;
  height: 42px;
  background: rgb(0, 157, 127);
  color: #fff;
  margin: 4px 0 18px;
  border-radius: 3px;
}

.line {
  border: 1px dashed grey;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 1px;
}

.withdrawTips {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 40px;
  font-family: SWISSC;
  color: #a5a6a8;

  span {
    margin-left: 8px;
  }
}

.listContent {
  padding: 12px 0 6px;
  height: 120px;
  overflow: hidden;
}

.cashList {
  margin: 0 30px;
  animation: scrollList linear infinite;
}

@keyframes scrollList {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

.list-item {
  color: #BDBDBD;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
</style>