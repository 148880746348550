<template>
	<div :class="['bottom-menu', isIndex ? 'bottom-menu-bar-index' : '']" v-if="!menuLoading">
		<div class="mask" v-if="$store.state.isWallet" @click.stop="tabMask"></div>
		<div class="bottom-menu__item" :class="{'menu-link-active':isActive('/all-game')}">
			<router-link to="/all-game" class="menu-link">
				<img class="mob-bottom-icon" src="@/assets/images/icon-games.png" alt="">
				<img class="mob-bottom-icon-active" src="@/assets/images/icon-games-ac.png" alt="">
				<span class="menu-link__label">{{ $t("所有游戏") }}</span>
			</router-link>
		</div>
		<div class="bottom-menu__item" v-if="menuList">
			<div @click="to(menuList[0].url)" class="menu-link">
				<img class="mob-bottom-icon" src="@/assets/images/icon-act.png" alt="">
				<span class="menu-link__label">{{ $t(menuList[0].name) }}</span>
			</div>
		</div>
		<div class="bottom-menu__item" v-else :class="{'menu-link-active':isActive('/activity-hall')}">
			<router-link to="/activity-hall" class="menu-link">
				<img class="mob-bottom-icon" src="@/assets/images/act-icon.png" width="20" height="20" />
				<img class="mob-bottom-icon-active" src="@/assets/images/act-icon-ac.png" width="20" height="20" />
				<span class="menu-link__label">{{ $t("活动厅") }}</span>
			</router-link>
		</div>
		<div class="bottom-menu__item" @click.stop="showWallet" :class="{'menu-link-active':$store.state.isWallet}">
			<div class="menu-link">
				<img class="mob-bottom-icon mob-modil-middle-icon" src="@/assets/images/wallet-icon.png"/>
				<img class="mob-bottom-icon-active mob-modil-middle-icon" src="@/assets/images/wallet-icon-ac.png" />
				<span class="menu-link__label">{{ $t('钱包') }}</span>
			</div>
		</div>
		<div class="bottom-menu__item" v-if="menuList">
			<div @click="to(menuList[1].url)" class="menu-link">
				<img class="mob-bottom-icon" src="@/assets/images/icon-cs.png" alt="">
				<span class="menu-link__label">{{ $t(menuList[1].name) }}</span>
			</div>
		</div>
		<div class="bottom-menu__item" v-else :class="{'menu-link-active':isActive('/team-center')}">
			<router-link to="/team-center" class="menu-link">
				<img class="mob-bottom-icon" src="@/assets/images/icon-tc.png" alt="">
				<img class="mob-bottom-icon-active" src="@/assets/images/icon-tc-ac.png" alt="">
				<span class="menu-link__label">{{ $t('团队中心') }}</span>
			</router-link>
		</div>
		<div class="bottom-menu__item" :class="{'menu-link-active':flagShow}">
			<button class="menu-link" @click="menuClick">
				<div class="new" v-if="$store.state.pdd.pddData && $store.state.pdd.pddData.remain_turntable_count"></div>
				<img class="mob-bottom-icon" src="@/assets/images/icon-menus.png" alt="">
				<img class="mob-bottom-icon-active" src="@/assets/images/icon-menus-ac.png" alt="">
				<span class="menu-link__label">{{ $t('菜单') }}</span>
			</button>
		</div>
	</div>
</template>

<script>
import svgIcon from '@/components/svg.vue'
import { url_menu } from '@/api/url.js'
export default {
	components: {
		svgIcon
	},
	name: 'Foot',
	data() {
		return {
			visible: false,
			tabscur: 0,
			isIndex: false,
			timeId: null,
			menuLoading: false
		};
	},
	watch: {
		"$store.state.isWallet": function (e) {
			clearTimeout(this.timeId)
			if (e) {
				this.isIndex = e
			} else {
				this.timeId = setTimeout(() => {
					this.isIndex = e
				}, 200)
			}
		}
	},
	async mounted() {
		await this.getMenu()
	},
	props:{
		flagShow:{
			type:Boolean
		}
	},
	computed: {
		menuList() {
			return this.$store.state.menuList
		}
	},
	beforeDestroy() {
		this.$store.commit("setIsWallet", false)
	},
	methods: {
		isActive(path){
			return this.$route.path==path && !this.$store.state.isWallet && !this.flagShow
		},
		to(url) {
			if (url.startsWith('http')) {
				location.href = url
			} else {
				this.$router.push(url)
			}
		},
		async getMenu() {
			if (this.menuList !== null) return;
			this.menuLoading = true
			const { data } = await url_menu()
			this.menuLoading = false
			if (data.code == 1) {
				if (data.data.length == 2) {
					this.$store.commit("menuList", data.data)
				} else {
					this.$store.commit("menuList", false)
				}
			}
		},
		tabMask() {
			if (this.$store.state.loading.wallet) return
			if (!this.$store.state.loading.wallet) {
				this.$store.state.isWallet = false
			}
		},
		/**
		 * 显示钱包
		 * */
		showWallet() {
			if (!this.$store.state.isLoginStatu) {
				this.showLoginReg(0)
				return
			}
			if (this.$store.state.loading.wallet) return
			this.$store.commit("setIsWallet", !this.$store.state.isWallet)
			if (this.$store.state.isWallet) this.$store.state.walletGetData++
		},
		menuClick() {
			this.$emit('menuClick')
		},
	},
}
</script>
<style scoped lang="less">
.tabbaricon {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bottom-menu {
	z-index: 304;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 95px;
	background-image: url(~@/assets/images/tabbar-bg.png);
	background-size: 100% 100%;
	display: none;
	grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
	grid-column-gap: 4px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 8px;
}

.bottom-menu-bar-index {
	z-index: 99999;
}

@media(max-width:768px) {
	.bottom-menu {
		display: grid;
		align-items: start;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 26px;
		height: 89px;
		height: calc(89px + constant(safe-area-inset-bottom));
		height: calc(89px + env(safe-area-inset-bottom));
	}
}

.bottom-menu__item {
	position: relative;
	height: 52px
}

.menu-link {
	position: relative;
	width: 100%;
	text-decoration: none;
	color: #3D7C69;
	justify-content: center;
	text-align: center;
	height: 100%;
	transform: translateZ(0)
}

.menu-link,
.menu-link--tooltip {
	display: flex;
	flex-direction: column;
	align-items: center
}

.menu-link--tooltip:hover {
	color: #fff
}

.menu-link--tooltip:hover>svg {
	fill: #fff
}

.menu-link--tooltip:hover:hover {
	color: #fff
}

.menu-link:before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
	top: -15px;
	left: 50%;
	transform: translate(-50%);
	background: transparent;
	filter: blur(0);
	transition: all .2s ease-in-out;
	z-index: -1
}

.menu-link svg {
	fill: #AED9CC;
	transition: fill .1s ease-in-out
}

.menu-link__label {
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	transition: color .1s ease-in-out
}


.menu-link__coin {
	position: absolute;
	top: 4px;
	left: 50%;
	transform: translate(-50%) scale(1);
	width: 16px;
	height: 16px;
	-webkit-animation: pulse-data-v-ce6ef5ae .7s linear infinite;
	animation: pulse-data-v-ce6ef5ae .7s linear infinite
}

.menu-link__coin--static {
	-webkit-animation: none;
	animation: none;
	transform: translate(0) scale(1)
}

.amount {
	min-width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 5px;
	padding-right: 5px;
	background: #3EAB7E;
	border-radius: 50px;
	border: 1px solid #0F5745;
	position: absolute;
	top: 1px;
	left: 48%;
	font-weight: 700;
	font-size: 10px;
	line-height: 8px;
	color: #fff
}

.amount--tag,
.is-bonuses {
	background-color: #1bb83d
}

@-webkit-keyframes pulse-data-v-ce6ef5ae {
	0% {
		transform: scale(1)
	}

	50% {
		transform: scale(1.2)
	}

	to {
		transform: scale(1)
	}
}

@keyframes pulse-data-v-ce6ef5ae {
	0% {
		transform: scale(1)
	}

	50% {
		transform: scale(1.2)
	}

	to {
		transform: scale(1)
	}
}

.contests-controls {
	position: fixed;
	right: 0;
	top: 112px;
	width: 40px;
	border-radius: 10px 0 0 10px;
	background-color: #009A7A;
	z-index: 10;
	transition: right .2s ease-in-out;
	cursor: pointer;
	padding: 4px
}

@media(max-width:1000px) {
	.contests-controls {
		position: static;
		width: auto;
		padding: 6px
	}
}

.contests-controls--move {
	right: 340px
}

.contests-controls--move-short {
	right: 325px
}

.contests-controls__item {
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .2s;
	position: relative;
	border-radius: 7px
}

@media(max-width:1000px) {
	.contests-controls__item {
		flex-direction: row-reverse;
		justify-content: flex-end;
		padding: 6px 7.5px
	}
}

@media(max-width:1000px) {
	.contests-controls__item-img {
		width: 25px;
		height: 25px
	}
}

.contests-controls__item:not(.contests-controls__item--active):hover {
	background-color: #3c485c
}

.contests-controls__item:not(.contests-controls__item--active):hover .contests-controls__item-tip {
	display: block
}

.contests-controls__item+.contests-controls__item {
	margin-top: 4px
}

.contests-controls__item--active {
	background: #D7FFC4
}

.contests-controls__item--active .contests-controls__item-tip {
	color: #fff
}

.contests-controls__item-tip {
	display: none;
	position: absolute;
	width: 106px;
	height: 38px;
	background: #263041;
	border-radius: 8px;
	left: -124px;
	top: 50%;
	transform: translateY(-50%);
	padding-left: 12px;
	line-height: 38px;
	font-weight: 500;
	font-size: 12px;
	color: #AED9CC
}

@media(max-width:1000px) {
	.contests-controls__item-tip {
		position: static;
		display: block;
		width: auto;
		height: auto;
		background: none;
		transform: none;
		line-height: 18px;
		font-size: 14px
	}
}

.contests-controls__item-tip:after {
	content: "";
	position: absolute;
	display: block;
	right: -6px;
	top: 0;
	width: 20px;
	height: 20px;
	background: #263041;
	transform: translateY(50%) rotate(135deg);
	border-radius: 2px;
	z-index: -1
}

@media(max-width:1000px) {
	.contests-controls__item-tip:after {
		display: none
	}
}


.mask {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999;
	height: 100%;
	width: 100%;
}

.item_icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.item_icon .iconfont {
	font-size: 24px;
	line-height: 24px;
}

.mob-bottom-icon {
	width: 36px;
	height: 36px;
	object-fit: cover;
}
.mob-bottom-icon-active{
	width: 36px;
	height: 36px;
	object-fit: cover;
	display: none;
}
.mob-modil-middle-icon{
	transform: scale(1.4) translateY(-8px);
}
.menu-link-active{
	.mob-bottom-icon{
		display: none;
	}
	.mob-bottom-icon-active{
		display: block;
	}
	.menu-link{
		color: #FFD700;
	}
}
.new{
	width: 8px;
  height: 8px;
  background-color: red;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 4px;
  border-radius: 50%;
}
</style>
